<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="isDetailView && typeof recordResponse !== 'undefined' && typeof recordResponse.user !== 'undefined'">
      <div class="custom-contianer">
        <div class="contents-header">
          <div class="separator">
            <p class="breadcrumb-menu">
              <router-link :to="{ name: 'Record', query: this.createSearchQuery()}" class="ashen-link">健康記録参照
              </router-link>
              <i class="fas fa-angle-right form-control-color"></i> 関係者健康記録一覧
            </p>
          </div>
          <div class="card border-0 text-first mt-3">
            <div class="row d-flex justify-content-between">
              <div v-if="prevUserString" class="col-6">
                <router-link :to="linkPrevUser" class="ashen-link mx-1" replace>
                  <i class="fas fa-angle-left"></i>
                  {{ prevUserString }}
                </router-link>
              </div>
              <div v-else class="col-6"></div>
              <div v-if="nextUserString" class="col-6 text-end">
                <router-link :to="linkNextUser" class="ashen-link mx-1" replace>
                  {{ nextUserString }}<i class="fas fa-angle-right mx-1"></i>
                </router-link>
              </div>
              <div v-else class="col-6"></div>
            </div>
          </div>
        </div>

        <div class="card card-detail mt-3">
          <div :class="{
                'bg-success' : enterStatus === 1,
                'bg-danger' : enterStatus === 2,
                'bg-none' : recordResponse.accountStatus ===  2,
              }" class="card-header">
            <!-- << bg-success or bg-danger or bg-nomal -->
            <img v-if="enterStatus === 0 || recordResponse.accountStatus ===  2"
                 class="width-18 height-18"
                 src="../assets/images/racket-gray.svg"/>
            <img v-else class="width-18 height-18" src="../assets/images/racket-white.svg"/>
            <span :class="{'color-33':enterStatus === 0 || recordResponse.accountStatus ===  2}"
                  class="p-l-10">{{
                recordResponse.tournamentName
              }}</span>
          </div>
          <div class="card-body pl-3">
            <div class="card-holder">
              <div class="row">
                <div class="enterStatus">
                  <h5 class="color-88 font-12">入場可否</h5>
                  <template v-if="recordResponse.accountStatus == 2">
                    <div v-html="showEnterStatus(3)"></div>
                  </template>
                  <template v-else>
                    <div v-html="showEnterStatus(enterStatus)"></div>
                  </template>
                </div>

                <div class="col mt-4 data-info">
                  <div class="row p-l-10">
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12 text-nowrap">姓名</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        　{{ recordResponse.user.lastName }} {{ recordResponse.user.firstName }}
                      </h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12 text-nowrap">入場管理番号</h5>
                      <h5 class="color-33 font-16 text-nowrap">
                        {{ recordResponse.user.userId }}
                      </h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12 text-nowrap">ロール</h5>
                      <h5 class="color-33 font-16 text-nowrap">{{ recordResponse.userRole }}</h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12 text-nowrap">メールアドレス</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        {{ recordResponse.user.email }}
                      </h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12 text-nowrap">電話番号</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        {{ recordResponse.user.phoneNo }}
                      </h5>
                    </div>
                  </div>
                  <div class="row p-l-10 mt-2">
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12">ステータス</h5>
                      <template v-if="recordResponse.accountStatus != 2">
                        <div class="d-inline-flex mb-3" v-html="populateReasons()">
                        </div>
                      </template>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12 text-nowrap">申告事項</h5>
                      <h5 class="color-33 font-16 text-nowrap">{{organization}}</h5>
                    </div>
                  </div>
                  <div class="row p-l-10">
                    <!--
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12">ワクチン接種</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        {{coronaVaccinationCount}}
                      </h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12">ワクチン接種日</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        <div v-if="recordResponse.user.coronaVaccinationCount!=0">
                          <h5 class="color-33 font-16 text-nowrap text-truncate">
                            {{recordResponse.user.latestCoronaVaccinationDate}}
                          </h5>
                        </div>
                      </h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12">PCR検査</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        {{latestPcrTestResult}}
                      </h5>
                    </div>
                    <div class="col p-l-0">
                      <h5 class="color-88 font-12">PCR検査日</h5>
                      <h5 class="color-33 font-16 text-nowrap text-truncate">
                        <div v-if="recordResponse.user.latestPcrTestResult!==0">
                          {{recordResponse.user.latestPcrTestDate}}
                        </div>
                      </h5>
                    </div>
                    -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="recordResponse.accountStatus == 2">
          <div class="datatable alert m-t-20">
            <span>アカウント停止中</span>
          </div>
        </template>
        <template v-else>
          <div class="datatable m-t-20">
            <Datatable
                :columns="recordColumns"
                :getData="getTournamentRecord"
                :loading="loading"
                :response="recordResponse"
                :sortable="true"
                @cta="clickAction">
            </Datatable>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {apiConfig, app, datatable, defaults} from "@/constants"
import Datatable from "../components/datatable/Datatable"
import Table from "../components/datatable/Table"
import Common from "jsadminclient/common"
import Helper from "jsadminclient/helper"
import {mapGetters} from "vuex"
import common from '../assets/jsadminclient/common'

export default {
  name: "UserRecord",
  components: {Datatable, Table},
  props: {
    tournamentId: {type: [Number, String], default: null},
    userId: {type: String, default: null},
    defaultResponse: {type: Object, default: null},
    defaultRecordResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      recordResponse: {
        sort: "date",
        order: "desc",
        accountStatus: "",
        prevUserId: "",
        nextUserId: "",
        prevUserName: "",
        nextUserName: "",
        tournamentName: "",
      },
      isDetailView: true,
      enterStatus: '',
      reloadKey: 1,
      keyword: "",
      loading: true,
      columns: datatable.columns.records,
      recordColumns: datatable.columns.record,
      //healthStatus: defaults.healthStatus,
      healthStatuses: defaults.healthStatuses,
      //accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      //alert: defaults.alert,
      alerts: defaults.alerts,
      roleId: defaults.roleId,
      organization: "",
      coronaVaccinationCount: defaults.coronaVaccinationCount,
      latestPcrTestResult: defaults.latestPcrTestResult,
      //tournamentId: defaults.tournamentId,
      tournamentList: {},
      //userId: 0,
      date: '',
      tournamentDetails: {},
      user: {}
    };
  },
  computed: {
    ...mapGetters(['config']),
    prevUserString() {
      if (this.$route.query.fromScan) {
        return null
      }
      if (this.recordResponse.prevUserName === null) {
        return null
      }
      return this.recordResponse.prevUserName + '(' + this.recordResponse.prevUserId + ')'
    },
    nextUserString() {
      if (this.$route.query.fromScan) {
        return null
      }
      if (this.recordResponse.nextUserName === null) {
        return null
      }
      return this.recordResponse.nextUserName + '(' + this.recordResponse.nextUserId + ')'
    },
    linkPrevUser() {
      return {
        name: "UserRecord",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.recordResponse.prevUserId
        },
        query: this.createSearchQuery()
      }
    },
    linkNextUser() {
      return {
        name: "UserRecord",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.recordResponse.nextUserId
        },
        query: this.createSearchQuery()
      }
    },
  },
  created() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
  },
  mounted() {
    this.restoreSearchQuery()
    this.initialPageLoadHandler()
    this.InitialRecordLoadHandler()
  },
  watch: {
    $route(to, from) {
      this.initialPageLoadHandler()
      this.InitialRecordLoadHandler()
    }
  },
  methods: {
    initialPageLoadHandler() {
      Object.assign(this.columns[3], {
        format: (alerts) => {
          return Helper.showAlerts(alerts)
        },
      });

      Object.assign(this.columns[5], {
        format: (user_tournament_id) => {
          return Helper.showCTA()
        },
      });


      $(".tooltip").hide();
    },
    InitialRecordLoadHandler() {
      // Detail records
      if (this.defaultRecordResponse) {
        this.recordResponse = this.defaultRecordResponse
      }

      Object.assign(this.recordColumns[0], {
        format: (healthStatus) => {
          return Helper.showHealthStatus(healthStatus)
        },
      })

      Object.assign(this.recordColumns[2], {
        format: (bodyTemp) => {
          return Helper.showBodyTemp(bodyTemp)
        },
      });

      Object.assign(this.recordColumns[3], {
        format: (alerts) => {
          return Helper.showAlerts(alerts)
        },
      })

      Object.assign(this.recordColumns[5], {
          format: (heat_stroke_score) => {
              return heat_stroke_score === null ? '' : heat_stroke_score+'点'
          },
      });

      Object.assign(this.recordColumns[6],  {
        format: (user_tournament_id) => {
          return Helper.showCTA()
        },
      });

      $(".tooltip").hide()
      this.getTournamentRecord()
    },
    showEnterStatus(enterStatus, extraCss = '') {
      return Helper.showEnterStatus(enterStatus, extraCss)
    },
    clickAction(key, row) {
      this.$router.push({
        name: "UserRecordDetail", params: {
          tournamentId: Number(this.tournamentId),
          userId: this.userId
        },
        query: Common.appendSearchQuery(this.createSearchQuery(), {date: row.date})
      })
    },
    recognizeEnterStatus() {
      const data = this.recordResponse
      // enterStatus: 0 = No input, 1 = Good, 2 = Bad,
      const noInputSpecifiedInBadReasons = data.badReasons && data.badReasons.length === 1 && data.badReasons.includes('未入力')

      this.enterStatus = data.enterStatus
    },
    populateReasons() {
      const data = this.recordResponse
      let html = ''
      for (let key in defaults.enterStatus.reasons) {
        let reason = defaults.enterStatus.reasons[key]
        if (data.badReasons.includes(reason.value)) {
          html += Helper.showHealthStatus(key, 'm-r-10')
        }
      }
      return html
    },
    async getTournamentRecord() {
      try {
        this.loading = true
        var api = new AdminApi.TournamentApi(this.getApiConfig());
        var result = await api.tournamentsTournamentIdUserRecordsUserIdGetAsync(
            this.tournamentId,
            this.userId,
            this.$route.query.healthStatus,
            this.$route.query.role,
            this.$route.query.coronaVaccinationCount,
            this.$route.query.pcrTestResultStatus,
            this.$route.query.alert,
            this.$route.query.accountStatus,
            this.$route.query.keyword,
            this.$route.query.sort,
            this.$route.query.order,
            this.recordResponse.sort,
            this.recordResponse.order,
            this.$route.query.fromScan ? 1 : 0
        )
        if (result) {
          this.loading = false
          this.recordResponse = result
          this.recognizeEnterStatus()
          this.user = this.recordResponse.user
          this.recordResponse.sort = result.sortItem
          this.recordResponse.order = result.orderItem
          this.organization = result.organization
          this.recordResponse.data = result.items.map((item) => {
            return {
              healthStatus: this.healthStatuses[item.healthStatus],
              user_id: result.user.userId,
              date: item.date,
              bodyTemp: item.bodyTemp,
              remarks: item.remarks,
              heatStrokeScore: item.heatStrokeScore,
              alerts: item.alerts,
            }
          })
          Common.updateCurrentPageQuery(this, this.createSearchQuery())
          this.coronaVaccinationCount = Common.getHasCoronaVaccination1(this.recordResponse.user.coronaVaccinationCount)
          this.latestPcrTestResult=Common.getLatestPcrTestResult(this.recordResponse.user.latestPcrTestResult)
        }
      } catch (e) {
        this.loading = false

        const isNotFoundError = Common.isNotFoundError(e.response)
        if (isNotFoundError) {
          Common.showToast("データが存在しません。", true)
          this.$router.push({name: 'Record', query: this.createSearchQuery()})
          return
        }

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'})
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query, {
        sortItem: this.recordResponse.sort,
        orderItem: this.recordResponse.order
      })
    },
    restoreSearchQuery() {
      Common.restoreQuery(this.recordResponse, 'sort', this.$route.query.sortItem)
      Common.restoreQuery(this.recordResponse, 'order', this.$route.query.orderItem)
    },
  },
};
</script>

<style lang="scss">

.userrecord table tr {
  th,
  td {
    &:nth-child(1) {
      width: 140px;
    }

    &:nth-child(2) {
      width: 100px;
    }

    &:nth-child(3) {
      width: 80px;
    }

    &:nth-child(4) {
      width: 195px;
    }

    &:nth-child(7) {
      width: 80px;
    }

    @media only screen and (max-width: 1000px) {
      &:nth-child(1) {
        width: 114px;
      }
      &:nth-child(2) {
        width: 85px;
      }
      &:nth-child(3) {
        width: 55px;
      }
      &:nth-child(5) {
        width: 140px;
        //.alerts > span:not(:nth-child(-n+3)) { // Hide after 3rd alert
        //  display: none;
        //}
      }
      &:nth-child(7) {
        width: 50px;
      }
    }
    @media only screen and (max-width: 800px) {
      &:nth-child(1) {
        width: 114px;
      }
      &:nth-child(2) {
        width: 85px;
      }
      &:nth-child(3) {
        width: 55px;
      }
      &:nth-child(5) {
        width: auto;
        //.alerts > span:not(:nth-child(-n+3)) { // Hide after 3rd alert
        //  display: none;
        //}
      }
      &:nth-child(7) {
        width: 50px;
      }
    }
  }
}

/*.select-style {*/
/*    height: 48px;*/
/*    width: 100%;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    background: #F2ECF0;*/
/*}*/

.tournament-search-button {
  height: 48px;
  background: #c95d6c;
  border-radius: 4px;
  color: #ffffff;
  width: 100%;
}

.filter-select {
  //background: #FAF7F9;
  //color: #888888;
  //width: 100%;
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  //color: #888888;
  //border: 1px solid #DEE2E6;
  //border-right: 0px;
  //width: 290px;
  height: 32px !important;
}

.btn-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bg-transparent:not(:hover) {
  background: transparent;
}

.fa {
  line-height: 0.98;
}

// Detail view
.custom-contianer {
  // padding: 0 22px;
}

.filter-select {
  background: #f2ecf0;
}

.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.date-div.spbetween {
  height: 76px;
  border-bottom: 1px solid #e2e2e2;
  line-height: 76px;
}

.content_wrapper {
  padding: 0 32px;
}

.content {
  top: 15px !important;
}

.nvlink-container {
  border-bottom: 1px solid #e2e2e2;
}

.spbetween {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.form-holder {
  padding: 0 80px;
}

.pd {
  padding: 5px 0;
}

textarea {
  background: #faf7f9;
  min-height: 207px;
}

.custom_btn {
  width: 350px;
}

.bg_black {
  background: #333333 0% 0% no-repeat padding-box;
}

.custom-contianer {
  label {
    padding: 17px 0 10px 0;

    &.pd {
      padding: 20px 0;
    }
  }
}

.userrecord { // Default: .record
  .bg-success {
    background-color: #7EC100 !important;
  }

  .bg-danger {
    background-color: #C1001B !important;
  }

  .bg-none {
    background-color: #ffffff !important;
  }

  .bg-nomal {
    background-color: #FFFFFF !important;
    color: #333 !important;
  }

  .text-success {
    color: #7EC100 !important;
  }

  .text-danger {
    color: #C1001B !important;
    //padding-left: 18px;
  }

  .enterStatus {
    width: 160px;
  }

  .data-info {
    max-width: calc(100% - 160px);
  }

  .card {
    &-header {
      color: white;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 500;
    }

    &-detail {
      background-color: #FAF7F9;
      border: 1px solid #DACED1;
      border-radius: 4px 4px 4px 4px;
      //.badge {
      //  &-status {
      //    margin-right: 8px;
      //  }
      //}
      .admission-status {
        background: #ffffff;
        border: 1px solid #eff1f3;
        height: 139px;
        width: 139px;
        position: relative;

        i {
          font-size: 111px;
          color: #c1001b;
          position: absolute;
          top: calc(50% - 55.5px);
          left: calc(50% - 55.5px);
          padding-left: 18px;
        }
      }
    }
  }

  .pd-top10 {
    padding-top: 10px;
  }

  .alert {
    height: 232px;
    width: 100%;
    border: 1px solid #DACED1;
    text-align: center;
    padding-top: 100px;

    span {
      color: #888888;
      font-size: 13px;
    }
  }
}
</style>
